import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { CONSTANT } from "../../../core/static-constant";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import {
    ROUTING_CONSTANT,
} from "../../../core/constant";
import * as yup from "yup";
import DataContext from "../../../contexts/dataContext";
import Loader from "../../Loader/loader";
import { decryptData } from "../../../core/auth.service";
import Select from "react-select";
import "../User/user.scss";
import { ProductService, RecommendationRuleService, RecipeBundleService } from "../../../services";
import { BotService } from "../../../services/bot.service";
import { Checkbox, FormControlLabel } from "@material-ui/core";

const AddEditRecommendationRule = () => {
    const { setRecommendationRuleData, recommendationRuleData, setMetricData, metricData, recipeBundleData, setRecipeBundleData, productData, setProductData }: any = useContext(DataContext);
    const params = useParams();
    const navigate = useNavigate();
    const [RecommendationRuleId, setRecommendationRuleId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isInvalidForm, setIsInvalidForm] = useState(false);
    const [selectedMetricName, setSelectedMetricName] = useState<string[]>([]);
    const [selectedMetricId, setSelectedMetricId] = useState<string[]>([]);

    const [selectedProductName, setSelectedProductName] = useState<string[]>([]);
    const [selectedProductId, setSelectedProductId] = useState<string[]>([]);

    const [selectedRecipeBundleName, setSelectedRecipeBundleName] = useState<string[]>([]);
    const [selectedRecipeBundleId, setSelectedRecipeBundleId] = useState<string[]>([]);

    const [checkedValues, setCheckedValues] = useState<any>([]);
    const [recommendationRuleAllData, setRecommendationRuleAllData] = useState<any>({
        rangeStartingValue: "",
        rangeEndingValue: "",
        gender: "",
        startingAge: "",
        endingAge: "",
        startingWeight: "",
        endingWeight: "",
        metricId: [{ metric: "", metricDesc: "" }],
        chatPrompts: [],
        productId: [{ product: "", productDesc: "", productHelps: "", productChatPrompt: "" }],
        recipeBundleId: [{ recipeBundle: "", recipeBundleDesc: "", recipeBundleHelps: "", recipeBundleChatPrompt: "" }],
        recommendations: [{ recommendationsTag: "", recommendationsShort: "", recommendationsLong: "", isPreview: false }]
    });

    useEffect(() => {
        const init = async () => {
            if (params && decryptData(params.id)) {
                setRecommendationRuleId(decryptData(params.id));
                getRecommendationRuleById(decryptData(params.id));
            }
        };
        init();
        if (metricData?.length === 0) {
            getAllMetricName();
        }
    }, []);

    const handleCheckboxChange = (event: any) => {
        const { value, checked } = event.target;
        if (checked) {
            setCheckedValues((prevChecked: any) => [...prevChecked, value]);
        } else {
            setCheckedValues((prevChecked: any) =>
                prevChecked.filter((gender: any) => gender !== value)
            );
        }
    };
    const handleCheckboxPreviewChange = (index: number) => {
        const newRecommendations = [...recommendationRuleAllData.recommendations];
        newRecommendations[index].isPreview = !newRecommendations[index].isPreview;
        setRecommendationRuleAllData({
            ...recommendationRuleAllData,
            recommendations: newRecommendations
        });
    };

    const getAllMetricName = async () => {
        try {
            let data: any = await BotService.getAllMetricName();
            if (data && data.status === CONSTANT.SUCCESS) {
                const responseData = data?.data?.metric;
                setMetricData(responseData);
            } else {
                toast.error(data.message);
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        }
    };

    const getAllProductName = async () => {
        try {
            let data: any = await ProductService.getAllProductName();
            if (data && data.status === CONSTANT.SUCCESS) {
                const responseData = data?.data?.product;
                setProductData(responseData);
            } else {
                toast.error(data.message);
            }
        } catch (error: any) {
            setProductData([]);
            toast.error(error?.response?.data?.message);
        }
    };

    const getAllRecipeBundleName = async () => {
        try {
            let data: any = await RecipeBundleService.getAllRecipeBundleName();
            if (data && data.status === CONSTANT.SUCCESS) {
                const responseData = data?.data?.recipeBundle;
                setRecipeBundleData(responseData);
            } else {
                toast.error(data.message);
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        }
    };

    useEffect(() => {
        if (productData?.length === 0) {
            getAllProductName();
        }
        if (recipeBundleData?.length === 0) {
            getAllRecipeBundleName();
        }
    }, []);

    const validationSchema = yup.object().shape({
        rangeStartingValue: yup.number().required("required"),
        rangeEndingValue: yup.number().required("required"),
        startingAge: yup.number().required("required"),
        endingAge: yup.number().required("required"),
        startingWeight: yup.number().required("required"),
        endingWeight: yup.number().required("required"),
    });

    const handleAddChatPrompt = () => {
        if (recommendationRuleAllData.chatPrompts.length === 0 || recommendationRuleAllData.chatPrompts[recommendationRuleAllData.chatPrompts.length - 1] !== '') {
            setRecommendationRuleAllData({
                ...recommendationRuleAllData,
                chatPrompts: [...recommendationRuleAllData.chatPrompts, ''],
            });
        }
    };

    const handleRemoveChatPrompt = (index: any) => {
        const updatedChatPrompts = recommendationRuleAllData.chatPrompts.filter((_: any, i: any) => i !== index).filter((prompt: any) => prompt !== '');
        setRecommendationRuleAllData({
            ...recommendationRuleAllData,
            chatPrompts: updatedChatPrompts,
        });
    };

    const handleChatPromptChange = (index: any, event: any) => {
        const newChatPrompts = recommendationRuleAllData.chatPrompts.map((prompt: any, i: any) =>
            i === index ? event.target.value : prompt
        ).filter((prompt: any) => prompt !== '');
        setRecommendationRuleAllData({
            ...recommendationRuleAllData,
            chatPrompts: newChatPrompts,
        });
    };
    const handleAddMetric = () => {
        const newOption = { id: Date.now(), metric: "", metricDesc: "" };
        const allOptionsValid = values.metricId.every((option: any) => option.metric !== "" && option.metricDesc !== "");

        if (allOptionsValid) {
            setValues((prevValues: any) => ({
                ...prevValues,
                metricId: [...prevValues.metricId, newOption],
            }));
        } else {
            toast.error(CONSTANT.VALID_OPTIONS);
        }
    };

    const handleRemoveMetric = (index: any) => {
        setValues((prevValues: any) => {
            const updatedOptions = [...prevValues.metricId];
            updatedOptions.splice(index, 1);
            return { ...prevValues, metricId: updatedOptions };
        });
    };

    const handleAddProduct = () => {
        const newOption = { id: Date.now(), product: "", productDesc: "", productHelps: "", productChatPrompt: "" };
        const allOptionsValid = values.productId.every((option: any) => option.product !== "" && option.productDesc !== "" && option.productHelps !== "" && option.productChatPrompt !== "");

        if (allOptionsValid) {
            setValues((prevValues: any) => ({
                ...prevValues,
                productId: [...prevValues.productId, newOption],
            }));
            // setSelectedProductName([]);
            // setSelectedProductId([]);
        } else {
            toast.error(CONSTANT.VALID_OPTIONS);
        }
    };

    const handleRemoveProduct = (index: any) => {
        setValues((prevValues: any) => {
            const updatedOptions = [...prevValues.productId];
            updatedOptions.splice(index, 1);
            return { ...prevValues, productId: updatedOptions };
        });
    };

    const handleAddRecipeBundle = () => {
        const newOption = { id: Date.now(), recipeBundle: "", recipeBundleDesc: "", recipeBundleHelps: "", recipeBundleChatPrompt: "" };
        const allOptionsValid = values.recipeBundleId.every((option: any) => option.recipeBundle !== "" && option.recipeBundleDesc !== "" && option.recipeBundleHelps !== "" && option.recipeBundleChatPrompt !== "");

        if (allOptionsValid) {
            setValues((prevValues: any) => ({
                ...prevValues,
                recipeBundleId: [...prevValues.recipeBundleId, newOption],
            }));
        } else {
            toast.error(CONSTANT.VALID_OPTIONS);
        }
    };

    const handleRemoveRecipeBundle = (index: any) => {
        setValues((prevValues: any) => {
            const updatedOptions = [...prevValues.recipeBundleId];
            updatedOptions.splice(index, 1);
            return { ...prevValues, recipeBundleId: updatedOptions };
        });
    };

    const handleAddRecommendationsShortLong = () => {
        const newOption = { recommendationsShort: "", recommendationsLong: "", recommendationsTag: "", isPreview :false };
        const allOptionsValid = values.recommendations.every((option: any) => option.recommendationsShort !== "" && option.recommendationsLong !== "" && option
            .recommendationsTag !== "");

        if (allOptionsValid) {
            setValues((prevValues: any) => ({
                ...prevValues,
                recommendations: [...prevValues.recommendations, newOption],
            }));
        } else {
            toast.error(CONSTANT.VALID_OPTIONS);
        }
    };

    const handleRemoveRecommendationsShortLong = (index: any) => {
        setValues((prevValues: any) => {
            const updatedOptions = [...prevValues.recommendations];
            updatedOptions.splice(index, 1);
            return { ...prevValues, recommendations: updatedOptions };
        });
    };

    const GenderOption = [
        { sourceName: CONSTANT.MALE, value: CONSTANT.MALE, label: CONSTANT.MALE, _id: '1' },
        { sourceName: CONSTANT.FEMALE, value: CONSTANT.FEMALE, label: CONSTANT.FEMALE, _id: '2' },
        { sourceName: CONSTANT.DEFAULT, value: CONSTANT.DEFAULT, label: CONSTANT.DEFAULT, _id: '3' },
    ];

    const { values, handleChange, handleSubmit, setValues } =
        useFormik({
            initialValues: { ...recommendationRuleAllData },
            validationSchema,

            onSubmit: async (values, action) => {
                try {
                    setIsLoading(true);
                    const filteredChatPrompts = recommendationRuleAllData.chatPrompts.filter((prompt: any) => prompt !== '');
                    const filteredOptions = values.recommendations.filter((option: any) => option.recommendationsShort !== "" && option.recommendationsLong !== "" && option.recommendationsTag !== "");
                    const payload = {
                        ...values,
                        recommendations: filteredOptions,
                        chatPrompts: filteredChatPrompts,
                        metricId: values.metricId.map((item: any) => ({
                            metricName: item.metricName,
                            metricId: item.metricId,
                            metricDesc: item.metricDesc,
                        })),
                        productId: values.productId.map((item: any) => ({
                            productDesc: item.productDesc,
                            productHelps: item.productHelps,
                            productChatPrompt: item.productChatPrompt,
                            productName: item.productName,
                            productId: item.productId,
                        })),
                        recipeBundleId: values.recipeBundleId.map((item: any) => ({
                            recipeBundleDesc: item.recipeBundleDesc,
                            recipeBundleHelps: item.recipeBundleHelps,
                            recipeBundleChatPrompt: item.recipeBundleChatPrompt,
                            recipeBundleName: item.recipeBundleName,
                            recipeBundleId: item.recipeBundleId
                        })),
                        gender: checkedValues
                    };
                    const data = RecommendationRuleId ? await RecommendationRuleService.updateRecommendationRuleById(RecommendationRuleId, payload) : await RecommendationRuleService.addRecommendationRule(payload);
                    setIsLoading(false);

                    if (data && data.status === CONSTANT.SUCCESS) {
                        toast.success(data.message);
                        const RecommendationRuleName = await RecommendationRuleService.getAllRecommendationRuleName();
                        setRecommendationRuleData(RecommendationRuleName?.data?.recommendationRule);
                        action.resetForm();
                        navigate(ROUTING_CONSTANT.RECOMMENDATION_RULE_VIEW);

                        const { _id, rangeStartingValue } = data.data;
                        if (RecommendationRuleId) {
                            const existingIndex = recommendationRuleData.findIndex((item: any) => item._id === _id);
                            if (existingIndex !== -1) {
                                setRecommendationRuleData((prevData: any) => {
                                    const newData = [...prevData];
                                    newData[existingIndex] = { _id, rangeStartingValue };
                                    return newData;
                                });
                            }
                        }
                    } else {
                        toast.error(data.message);
                    }
                } catch (error: any) {
                    toast.error(error?.response?.data?.error);
                    setIsLoading(false);
                }
            },
        });

    const getRecommendationRuleById = async (id: any) => {
        try {
            setIsLoading(true);
            const response = await RecommendationRuleService.getRecommendationRuleById(id);
            setIsLoading(false);
            if (response.status === CONSTANT.SUCCESS) {
                setSelectedMetricName(response?.data?.recommendationRule?.metricId?.map((ele: any) => ele.metricName) || []);
                setSelectedMetricId(response?.data?.recommendationRule?.metricId?.map((ele: any) => ele.metricId) || []);

                setRecommendationRuleAllData(response?.data?.recommendationRule);
                setValues({ ...response?.data?.recommendationRule });

                setSelectedProductName(response?.data?.recommendationRule?.productId?.map((ele: any) => ele.productName) || []);
                setSelectedProductId(response?.data?.recommendationRule?.productId?.map((ele: any) => ele.productId) || []);

                setSelectedRecipeBundleName(response?.data?.recommendationRule?.recipeBundleId?.map((ele: any) => ele.recipeBundleName) || []);
                setSelectedRecipeBundleId(response?.data?.recommendationRule?.recipeBundleId?.map((ele: any) => ele.recipeBundleId) || []);

                if (response?.data?.recommendationRule) {
                    const selectedGendersFromData = response?.data?.recommendationRule?.gender || [];
                    setCheckedValues(selectedGendersFromData);
                }
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.error);
            setIsLoading(false);
        }
    };

    const onCancelClick = () => {
        navigate(ROUTING_CONSTANT.RECOMMENDATION_RULE_VIEW);
    };

    const handleSave = () => {
        setIsInvalidForm(true);
    };
    function style() {
        return {
            control: (baseStyles: any) => ({
                ...baseStyles,
                borderRadius: "10px",
            }),
        };
    }

    const customStyles = style();

    return (
        <>
            <div className="page container-xxl align-items-center">
                {isLoading && (
                    <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
                        <Loader />
                    </div>
                )}
                <Card className="w-100">
                    <CardBody>
                        <h3 className="text-center mt-3 mb-5 text-primary">
                            <strong>{RecommendationRuleId ? CONSTANT.UPDATE : CONSTANT.ADD} Recommendation Rule</strong>
                        </h3>
                        <form onSubmit={handleSubmit}>
                            <div className="row g-4">
                                <div className="col-sm-12 col-md-6">
                                    <label className="form-labels"> Range Starting Value*</label>

                                    <FormGroup id="rangeStartingValue">
                                        <Input
                                            placeholder="Range Starting Value"
                                            className={`${isInvalidForm && !values.rangeStartingValue
                                                ? "border border-danger input-form"
                                                : "input-form"
                                                }`}
                                            name="rangeStartingValue"
                                            value={values.rangeStartingValue}
                                            onChange={handleChange}
                                            type="number"
                                        />
                                    </FormGroup>
                                    {isInvalidForm && !values.rangeStartingValue && (
                                        <p className="text-danger ms-2">
                                            <small>{CONSTANT.REQUIRED}</small>
                                        </p>
                                    )}
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <label className="form-labels"> Range Ending Value*</label>

                                    <FormGroup id="rangeEndingValue">
                                        <Input
                                            placeholder="Range Ending Value"
                                            className={`${isInvalidForm && !values.rangeEndingValue
                                                ? "border border-danger input-form"
                                                : "input-form"
                                                }`}
                                            name="rangeEndingValue"
                                            value={values.rangeEndingValue}
                                            onChange={handleChange}
                                            type="number"
                                        />
                                    </FormGroup>
                                    {isInvalidForm && !values.rangeEndingValue && (
                                        <p className="text-danger ms-2">
                                            <small>{CONSTANT.REQUIRED}</small>
                                        </p>
                                    )}
                                </div>

                                <div className="col-sm-12 col-md-6">
                                    <label className="form-labels"> Starting Weight*</label>

                                    <FormGroup id="startingWeight">
                                        <Input
                                            placeholder="Starting Weight"
                                            className={`${isInvalidForm && !values.startingWeight
                                                ? "border border-danger input-form"
                                                : "input-form"
                                                }`}
                                            name="startingWeight"
                                            value={values.startingWeight}
                                            onChange={handleChange}
                                            type="number"
                                        />
                                    </FormGroup>
                                    {isInvalidForm && !values.startingWeight && (
                                        <p className="text-danger ms-2">
                                            <small>{CONSTANT.REQUIRED}</small>
                                        </p>
                                    )}
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <label className="form-labels"> Ending Weight*</label>

                                    <FormGroup id="endingWeight">
                                        <Input
                                            placeholder="Ending Weight"
                                            className={`${isInvalidForm && !values.endingWeight
                                                ? "border border-danger input-form"
                                                : "input-form"
                                                }`}
                                            name="endingWeight"
                                            value={values.endingWeight}
                                            onChange={handleChange}
                                            type="number"
                                        />
                                    </FormGroup>
                                    {isInvalidForm && !values.endingWeight && (
                                        <p className="text-danger ms-2">
                                            <small>{CONSTANT.REQUIRED}</small>
                                        </p>
                                    )}
                                </div>

                                <div className="col-sm-12 col-md-6">
                                    <label className="form-labels"> Starting Age*</label>

                                    <FormGroup id="startingAge">
                                        <Input
                                            placeholder="Starting Age"
                                            className={`${isInvalidForm && !values.startingAge
                                                ? "border border-danger input-form"
                                                : "input-form"
                                                }`}
                                            name="startingAge"
                                            value={values.startingAge}
                                            onChange={handleChange}
                                            type="number"
                                        />
                                    </FormGroup>
                                    {isInvalidForm && !values.startingAge && (
                                        <p className="text-danger ms-2">
                                            <small>{CONSTANT.REQUIRED}</small>
                                        </p>
                                    )}
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <label className="form-labels"> Ending Age*</label>

                                    <FormGroup id="endingAge">
                                        <Input
                                            placeholder="Ending Age"
                                            className={`${isInvalidForm && !values.endingAge
                                                ? "border border-danger input-form"
                                                : "input-form"
                                                }`}
                                            name="endingAge"
                                            value={values.endingAge}
                                            onChange={handleChange}
                                            type="number"
                                        />
                                    </FormGroup>
                                    {isInvalidForm && !values.endingAge && (
                                        <p className="text-danger ms-2">
                                            <small>{CONSTANT.REQUIRED}</small>
                                        </p>
                                    )}
                                </div>
                                <div className="col-sm-12 col-md-12">
                                    <FormGroup id="options">
                                        <>
                                            <div>
                                                <><label className="form-labels">Metric</label><svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    fill="currentColor"
                                                    className="bi bi-plus-circle sku-icon cursor-pointer"
                                                    viewBox="0 0 16 16"
                                                    onClick={handleAddMetric}
                                                >
                                                    <path
                                                        d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                    <path
                                                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                                </svg></>
                                            </div>
                                        </>
                                        <div>
                                            {values?.metricId?.map((option: any, index: any) => {
                                                return (
                                                    <div key={index} className="">
                                                        <div className="d-flex gap-2 mt-3 align-items-center justify-content-center">
                                                            <div className="col-sm-6 col-xs-6 col-md-6" key="metric">
                                                                <FormGroup id="metric">
                                                                    <Select
                                                                        styles={customStyles}
                                                                        onChange={(selectedOption) => {
                                                                            const updatedMetricId = [...values.metricId];
                                                                            updatedMetricId[index] = {
                                                                                ...updatedMetricId[index],
                                                                                metric: selectedOption ? selectedOption.value : "",
                                                                                metricName: selectedOption ? selectedOption.label : "",
                                                                                metricId: selectedOption ? selectedOption.value : "",
                                                                            };
                                                                            setValues({
                                                                                ...values,
                                                                                metricId: updatedMetricId,
                                                                            });

                                                                            const newSelectedMetricNames = [...selectedMetricName];
                                                                            const newSelectedMetricIds = [...selectedMetricId];
                                                                            newSelectedMetricNames[index] = selectedOption ? selectedOption.label : "";
                                                                            newSelectedMetricIds[index] = selectedOption ? selectedOption.value : "";
                                                                            setSelectedMetricName(newSelectedMetricNames);
                                                                            setSelectedMetricId(newSelectedMetricIds);
                                                                        }}
                                                                        options={
                                                                            metricData &&
                                                                            metricData.map((metric: any) => ({
                                                                                value: metric._id,
                                                                                label: metric.metricName,
                                                                            }))
                                                                        }
                                                                        placeholder="Select Metric"
                                                                        value={
                                                                            selectedMetricName[index] && selectedMetricId[index]
                                                                                ? {
                                                                                    value: selectedMetricId[index],
                                                                                    label: selectedMetricName[index],
                                                                                }
                                                                                : null
                                                                        }
                                                                        isSearchable={true}
                                                                        className={`${isInvalidForm && selectedMetricName.length === 0
                                                                            ? "border border-danger"
                                                                            : ""
                                                                            }`}
                                                                    />
                                                                </FormGroup>
                                                                {isInvalidForm && selectedMetricName.length === 0 && (
                                                                    <p className="text-danger ms-2">
                                                                        <small>{CONSTANT.METRIC_ID}</small>
                                                                    </p>
                                                                )}
                                                            </div>
                                                            <Input
                                                                placeholder="Metric Description"
                                                                className={`${isInvalidForm && !option.metricDesc ? "border input-form" : "input-form"}`}
                                                                value={option.metricDesc}
                                                                name={`metricId[${index}].metricDesc`}
                                                                onChange={handleChange} />
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="16"
                                                                height="16"
                                                                fill="currentColor"
                                                                className="bi bi-dash-circle sku-icon cursor-pointer"
                                                                viewBox="0 0 16 16"
                                                                onClick={() => handleRemoveMetric(index)}
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zm-3.5 7a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5z" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </FormGroup>
                                </div>

                                <div className="col-sm-12 col-md-6" key="gender">
                                    <label className="form-labels">Select Gender*</label>

                                    <FormGroup id="gender">
                                        {GenderOption.map((option) => (
                                            <FormControlLabel
                                                key={option._id}
                                                control={
                                                    <Checkbox
                                                        value={option.value}
                                                        onChange={handleCheckboxChange}
                                                        checked={checkedValues.includes(option.value)}
                                                    />
                                                }
                                                label={option.sourceName}
                                            />
                                        ))}
                                    </FormGroup>

                                </div>

                                <div className="col-sm-12 col-md-12">
                                    <FormGroup id="options">
                                        <>
                                            <div>
                                                <><label className="form-labels">Product</label><svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    fill="currentColor"
                                                    className="bi bi-plus-circle sku-icon cursor-pointer"
                                                    viewBox="0 0 16 16"
                                                    onClick={handleAddProduct}
                                                >
                                                    <path
                                                        d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                    <path
                                                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                                </svg></>
                                            </div>
                                        </>
                                        <div>
                                            {values?.productId?.map((option: any, index: any) => {
                                                return (
                                                    <div key={index} className="">
                                                        <div className="d-flex gap-2 mt-3 align-items-center justify-content-center">
                                                            <div className="col-sm-6 col-md-3" key="product">
                                                                <FormGroup id="product">
                                                                    <Select
                                                                        styles={customStyles}
                                                                        onChange={(selectedOption) => {
                                                                            const updatedProductId = [...values.productId];
                                                                            updatedProductId[index] = {
                                                                                ...updatedProductId[index],
                                                                                product: selectedOption ? selectedOption.value : "",
                                                                                productName: selectedOption ? selectedOption.label : "",
                                                                                productId: selectedOption ? selectedOption.value : "",
                                                                            };
                                                                            setValues({
                                                                                ...values,
                                                                                productId: updatedProductId,
                                                                            });

                                                                            const newSelectedProductNames = [...selectedProductName];
                                                                            const newSelectedProductIds = [...selectedProductId];
                                                                            newSelectedProductNames[index] = selectedOption ? selectedOption.label : "";
                                                                            newSelectedProductIds[index] = selectedOption ? selectedOption.value : "";
                                                                            setSelectedProductName(newSelectedProductNames);
                                                                            setSelectedProductId(newSelectedProductIds);
                                                                        }}
                                                                        options={
                                                                            productData &&
                                                                            productData.map((product: any) => ({
                                                                                value: product._id,
                                                                                label: product.productName,
                                                                            }))
                                                                        }
                                                                        placeholder="Select Product"
                                                                        value={
                                                                            selectedProductName[index] && selectedProductId[index]
                                                                                ? {
                                                                                    value: selectedProductId[index],
                                                                                    label: selectedProductName[index],
                                                                                }
                                                                                : null
                                                                        }
                                                                        isSearchable={true}
                                                                        className={`${isInvalidForm && selectedProductName.length === 0
                                                                            ? "border border-danger"
                                                                            : ""
                                                                            }`}
                                                                    />
                                                                </FormGroup>
                                                                {isInvalidForm && selectedProductName.length === 0 && (
                                                                    <p className="text-danger ms-2">
                                                                        <small>{CONSTANT.PRODUCT_ID}</small>
                                                                    </p>
                                                                )}
                                                            </div>
                                                            <Input
                                                                placeholder="Product Description"
                                                                className={`${isInvalidForm && !option.productDesc ? "border input-form" : "input-form"}`}
                                                                value={option.productDesc}
                                                                name={`productId[${index}].productDesc`}
                                                                onChange={handleChange} />
                                                            <Input
                                                                placeholder="Product Helps"
                                                                className={`${isInvalidForm && !option.productHelps ? "border input-form" : "input-form"}`}
                                                                value={option.productHelps}
                                                                name={`productId[${index}].productHelps`}
                                                                onChange={handleChange} />
                                                            <Input
                                                                placeholder="Product Chat Prompts"
                                                                className={`${isInvalidForm && !option.productChatPrompt ? "border input-form" : "input-form"}`}
                                                                value={option.productChatPrompt}
                                                                name={`productId[${index}].productChatPrompt`}
                                                                onChange={handleChange} />
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="16"
                                                                height="16"
                                                                fill="currentColor"
                                                                className="bi bi-dash-circle sku-icon cursor-pointer"
                                                                viewBox="0 0 16 16"
                                                                onClick={() => handleRemoveProduct(index)}
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zm-3.5 7a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5z" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </FormGroup>
                                </div>

                                <div className="col-sm-12 col-md-12">
                                    <FormGroup id="options">
                                        <>
                                            <div>
                                                <><label className="form-labels">Recipe Bundle</label><svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    fill="currentColor"
                                                    className="bi bi-plus-circle sku-icon cursor-pointer"
                                                    viewBox="0 0 16 16"
                                                    onClick={handleAddRecipeBundle}
                                                >
                                                    <path
                                                        d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                    <path
                                                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                                </svg></>
                                            </div>
                                        </>
                                        <div>
                                            {values?.recipeBundleId?.map((option: any, index: any) => {
                                                return (
                                                    <div key={index} className="">
                                                        <div className="d-flex gap-2 mt-3 align-items-center justify-content-center">
                                                            <div className="col-sm-6 col-md-3" key="recipeBundle">
                                                                <FormGroup id="recipeBundle">
                                                                    <Select
                                                                        styles={customStyles}
                                                                        onChange={(selectedOption) => {
                                                                            const updatedRecipeBundleId = [...values.recipeBundleId];
                                                                            updatedRecipeBundleId[index] = {
                                                                                ...updatedRecipeBundleId[index],
                                                                                recipeBundle: selectedOption ? selectedOption.value : "",
                                                                                recipeBundleName: selectedOption ? selectedOption.label : "",
                                                                                recipeBundleId: selectedOption ? selectedOption.value : "",
                                                                            };
                                                                            setValues({
                                                                                ...values,
                                                                                recipeBundleId: updatedRecipeBundleId,
                                                                            });

                                                                            const newSelectedRecipeBundleNames = [...selectedRecipeBundleName];
                                                                            const newSelectedRecipeBundleIds = [...selectedRecipeBundleId];
                                                                            newSelectedRecipeBundleNames[index] = selectedOption ? selectedOption.label : "";
                                                                            newSelectedRecipeBundleIds[index] = selectedOption ? selectedOption.value : "";
                                                                            setSelectedRecipeBundleName(newSelectedRecipeBundleNames);
                                                                            setSelectedRecipeBundleId(newSelectedRecipeBundleIds);
                                                                        }}
                                                                        options={
                                                                            recipeBundleData &&
                                                                            recipeBundleData.map((recipeBundle: any) => ({
                                                                                value: recipeBundle._id,
                                                                                label: recipeBundle.recipeBundleName,
                                                                            }))
                                                                        }
                                                                        placeholder="Select Recipe Bundle"
                                                                        value={
                                                                            selectedRecipeBundleName[index] && selectedRecipeBundleId[index]
                                                                                ? {
                                                                                    value: selectedRecipeBundleId[index],
                                                                                    label: selectedRecipeBundleName[index],
                                                                                }
                                                                                : null
                                                                        }
                                                                        isSearchable={true}
                                                                        className={`${isInvalidForm && selectedRecipeBundleName.length === 0
                                                                            ? "border border-danger"
                                                                            : ""
                                                                            }`}
                                                                    />
                                                                </FormGroup>
                                                                {isInvalidForm && selectedRecipeBundleName.length === 0 && (
                                                                    <p className="text-danger ms-2">
                                                                        <small>{CONSTANT.RECIPE_BUNDLE_ID}</small>
                                                                    </p>
                                                                )}
                                                            </div>
                                                            <Input
                                                                placeholder="Recipe Bundle Description"
                                                                className={`${isInvalidForm && !option.recipeBundleDesc ? "border input-form" : "input-form"}`}
                                                                value={option.recipeBundleDesc}
                                                                name={`recipeBundleId[${index}].recipeBundleDesc`}
                                                                onChange={handleChange} />
                                                            <Input
                                                                placeholder="Recipe Bundle Helps"
                                                                className={`${isInvalidForm && !option.recipeBundleHelps ? "border input-form" : "input-form"}`}
                                                                value={option.recipeBundleHelps}
                                                                name={`recipeBundleId[${index}].recipeBundleHelps`}
                                                                onChange={handleChange} />
                                                            <Input
                                                                placeholder="Recipe Bundle Chat Prompts"
                                                                className={`${isInvalidForm && !option.recipeBundleChatPrompt ? "border input-form" : "input-form"}`}
                                                                value={option.recipeBundleChatPrompt}
                                                                name={`recipeBundleId[${index}].recipeBundleChatPrompt`}
                                                                onChange={handleChange} />
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="16"
                                                                height="16"
                                                                fill="currentColor"
                                                                className="bi bi-dash-circle sku-icon cursor-pointer"
                                                                viewBox="0 0 16 16"
                                                                onClick={() => handleRemoveRecipeBundle(index)}
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zm-3.5 7a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5z" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </FormGroup>
                                </div>
                                <div className="col-sm-12 col-md-12">
                                    <FormGroup id="options">
                                        <>
                                            <div>
                                                <><label className="form-labels">Recommendations</label><svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    fill="currentColor"
                                                    className="bi bi-plus-circle sku-icon cursor-pointer"
                                                    viewBox="0 0 16 16"
                                                    onClick={handleAddRecommendationsShortLong}
                                                >
                                                    <path
                                                        d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                    <path
                                                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                                </svg></>
                                            </div>
                                        </>
                                        <div>
                                            {values?.recommendations?.map((option: any, index: any) => {
                                                return (
                                                    <div key={index} className="">
                                                        <div className="d-flex gap-2 align-items-center justify-content-center mt-3">
                                                            <select
                                                                className={`${isInvalidForm && option?.recommendationsTag?.length === 0
                                                                    ? "border border-danger  form-select form-selet-lg input-form"
                                                                    : "form-select form-selet-lg input-form"
                                                                    }`}
                                                                name={`recommendations[${index}].recommendationsTag`}
                                                                value={option.recommendationsTag}
                                                                onChange={handleChange}
                                                            >
                                                                <option value="">Select Recommendation Tag</option>
                                                                <option value={CONSTANT.RECOMMENDATION_TAG.INCREASE}>
                                                                    {CONSTANT.RECOMMENDATION_TAG.INCREASE}
                                                                </option>
                                                                <option value={CONSTANT.RECOMMENDATION_TAG.MAINTAIN}>
                                                                    {CONSTANT.RECOMMENDATION_TAG.MAINTAIN}
                                                                </option>
                                                                <option value={CONSTANT.RECOMMENDATION_TAG.REDUCE}>
                                                                    {CONSTANT.RECOMMENDATION_TAG.REDUCE}
                                                                </option>
                                                            </select>
                                                            <Input
                                                                placeholder="Recommendations Short"
                                                                className={`${isInvalidForm && !option.recommendationsShort ? "border input-form" : "input-form"}`}
                                                                value={option.recommendationsShort}
                                                                name={`recommendations[${index}].recommendationsShort`}
                                                                onChange={handleChange} />
                                                            <Input
                                                                placeholder="Recommendations Long"
                                                                className={`${isInvalidForm && !option.recommendationsLong ? "border input-form" : "input-form"}`}
                                                                value={option.recommendationsLong}
                                                                name={`recommendations[${index}].recommendationsLong`}
                                                                onChange={handleChange} />
                                                            <Checkbox
                                                                checked={option.isPreview}
                                                                onChange={() => handleCheckboxPreviewChange(index)}
                                                            />
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="16"
                                                                height="16"
                                                                fill="currentColor"
                                                                className="bi bi-dash-circle sku-icon cursor-pointer"
                                                                viewBox="0 0 16 16"
                                                                onClick={() => handleRemoveRecommendationsShortLong(index)}
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zm-3.5 7a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5z" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    </FormGroup>
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <FormGroup id="options">
                                        <div>
                                            <>
                                                <label className="form-labels">Chat Prompts</label>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    fill="currentColor"
                                                    className="bi bi-plus-circle sku-icon cursor-pointer"
                                                    viewBox="0 0 16 16"
                                                    onClick={handleAddChatPrompt}
                                                >
                                                    <path
                                                        d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                    <path
                                                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                                </svg>
                                            </>
                                        </div>
                                        <div>
                                            {recommendationRuleAllData?.chatPrompts.map((prompt: any, index: any) => (
                                                <div key={index} className="d-flex align-items-center justify-content-center mt-1">
                                                    <Input
                                                        type="text"
                                                        value={prompt}
                                                        placeholder="Chat Prompt"
                                                        onChange={(event) => handleChatPromptChange(index, event)}
                                                        className={`${isInvalidForm && !values.chatPrompts
                                                            ? "border input-form"
                                                            : "input-form"
                                                            }`}
                                                    />
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        className="bi bi-dash-circle sku-icon cursor-pointer mt-1"
                                                        viewBox="0 0 16 16"
                                                        onClick={() => handleRemoveChatPrompt(index)}
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zm-3.5 7a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5z" />
                                                    </svg>
                                                </div>
                                            ))}
                                        </div>
                                    </FormGroup>
                                </div>
                                <div>
                                    <Button
                                        type="submit"
                                        onClick={handleSave}
                                        isDisable={isLoading}
                                        className={`w-25 mt-5 process-btn ${isLoading ? " disabled-button" : "bg-info text-white"
                                            }`}
                                    >
                                        {RecommendationRuleId ? CONSTANT.UPDATE : CONSTANT.ADD} Recommendation Rule
                                    </Button>
                                    <Button
                                        type="button"
                                        color="danger"
                                        className="w-25 mt-5 cancel-btn float-end"
                                        onClick={onCancelClick}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </CardBody>
                </Card>
            </div>
        </>
    );
};

export default AddEditRecommendationRule;
